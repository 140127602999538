body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  cursor: none;

}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* import nats font from public folder */
@font-face {
  font-family: 'NATS';
  src: url('../public/NATS-Regular.ttf') format('truetype');
}

.title-with-sideline {
  font-family: 'NATS';
  font-size: 128px;
  font-weight: 400;
  color: #ffffff;
  line-height: 8rem;
}

.sideline {
  border-radius: 3px;
  height: auto;
}

.sideline.right {
  margin-left: 3rem;
}

.sideline.left {
  margin-right: 3rem;
}

p {
  margin: 0;
  font-family: 'NATS';
  font-size: 22px;
  font-weight: 400;
}

h1 {
  margin: 0;
  display: block;
  margin-block-start: 1rem;
  margin-block-end: 1rem;
  font-weight: 400;
  font-size: 128px;
}

h2 {
  margin: 0;
  display: block;
  margin-block-start: 1rem;
  margin-block-end: 1rem;
  font-weight: 400;
  font-size: 80px;
}

h3 {
  font-family: "NATS";
  margin: 0;
  display: block;
  margin-block-start: 1rem;
  margin-block-end: 1rem;
  font-weight: 400;
  font-size: 80px;
}

.navbar {
  font-family: 'NATS';
  padding-right: 0.2em;
  font-size: 40px;
  font-weight: 400;
  color: #ffffff;
}

.navbar.open {
  height: 100vh;
  backdrop-filter: blur(10px);
}

.navbar::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 50%);
  z-index: -1;
  opacity: 0;
  transition: opacity 1.5s ease-in-out;
}

.sticky::before {
  opacity: 1;
  transition: opacity 1.5s ease-in-out;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}

@keyframes float {
	0% {
		transform: translatey(0px) rotate(-54deg);
	}
	50% {
		transform: translatey(-20px) rotate(-52deg);
	}
	100% {
		transform: translatey(0px) rotate(-54deg);
	}
}

@keyframes cursorAnimation {
  0% {
    transform: scale(1) translate(-50%, -50%);
    opacity: 1;
  }
  50% {
    transform: scale(1.1) translate(-45%, -45%);
    opacity: 0.98;
  }
  100% {
    transform: scale(1) translate(-50%, -50%);
    opacity: 1;
  }
}

.customCursor {
  position: absolute;
  top: 0; /* Center vertically */
  left: 0; /* Center horizontally */
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  pointer-events: none;
  z-index: 1;
  transition: background-color 0.5s ease-in-out, width 0.5s ease-in-out;
  transform-origin: center;
  animation: cursorAnimation 2s infinite;
}

.customCursor.flat {
  width: 4px;
  transform-origin: center;
  background-color: white;
  transition: background-color 0.5s ease-in-out, width 0.5s ease-in-out;
}


.spacer {
  height: 400px;
}

.text-spacer {
  padding-top: 13rem;
}

.project-container {
  margin-top:  4rem;
}

@media (max-width: 1199.98px) {
  .spacer {
    height: 50px;
  }
  .text-spacer {
    padding-top: 3rem;
  }

  .project-container {
    margin-top:  0rem;
  }
}

@keyframes cursorAnimation {
  0% {
    transform: scale(1) translate(-50%, -50%);
    opacity: 1;
  }
  50% {
    transform: scale(1.1) translate(-45%, -45%);
    opacity: 0.98;
  }
  100% {
    transform: scale(1) translate(-50%, -50%);
    opacity: 1;
  }
}

.clip-circle {
  clip-path: circle(165px at center);
  transition: clip-path 1s ease-in-out 0.5s, width 1s ease-in-out 1s, height 1s ease-in-out 1s, transform 1s ease-in-out;
}