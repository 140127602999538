.burger .line {
  width: 100%;
  height: 3px;
  border-radius: 3px;
  background-color: #ffffff;
  margin: 1rem 0;
  transition: .5s ease-in-out;
}

.burger .line {
  transform-origin: left center;
}

.burger.open .line:nth-child(1) {
  transform: rotate(45deg);
  width: 134%;
}

.burger.open .line:nth-child(2) {
  opacity: 0;
  transform: translate(20px);
}

.burger.open .line:nth-child(3) {
  transform: rotate(-45deg);
  width: 134%;
}