.slide-right {
    opacity: 0;
    transform: translateX(-200px);
    transition: opacity 2s, transform 2s;
}

.slide-left {
    opacity: 0;
    transform: translateX(200px);
    transition: opacity 2s, transform 2s;
}

.slide-right.visible, .slide-left.visible {
    opacity: 1;
    transform: translateX(0);
}